import React, { useEffect, useState } from "react";
import Widget from "components/common/widget";
import InputText from "components/common/inputText";
import ButtonText from "components/common/buttonText";
import WidgetLoadingSkeleton from "components/common/widgetLoadingSkeleton";
import { useTranslation } from "react-i18next";
import { useMessages } from "context/messages";
import { updateOrganization, getOrganizationForUser } from "api/organization";
import InputEmail from "components/common/inputEmail";
import { useParams } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

const SettingsPage = () => {
    const { organizationExtId } = useParams();
    const [organization, setOrganization] = useState();
    const [isSaving, setIsSaving] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const { t } = useTranslation();
    const { showToast } = useMessages();
    const { getAccessTokenSilently } = useAuth0();
    const [defaultStartTime, setDefaultStartTime] = useState();
    const [defaultEndTime, setDefaultEndTime] = useState();

    useEffect(() => {

        setIsLoading(true);

        const load = async () => {
            try {
                const token = await getAccessTokenSilently();
                const organizationResult = await getOrganizationForUser(token, organizationExtId);

                if (organizationResult && !organizationResult.error) {
                    console.log(organizationResult);
                    setOrganization(organizationResult);
                } else {
                    showToast(t('generic.errorTitle'), t('generic.errorDescription'), 'error');
                }
            } catch (error) {
                console.error(error);
                showToast(t('generic.errorTitle'), t('generic.errorDescription'), 'error');
            }

            const defaultStartTimeCookie = document.cookie.split('; ').find(row => row.startsWith('defaultStartTime'));
            const defaultEndTimeCookie = document.cookie.split('; ').find(row => row.startsWith('defaultEndTime'));

            if (defaultStartTimeCookie) {
                const defaultStartTimeCookieValue = defaultStartTimeCookie.split('=')[1];
                setDefaultStartTime(defaultStartTimeCookieValue);
            }

            if (defaultEndTimeCookie) {
                const defaultEndTimeCookieValue = defaultEndTimeCookie.split('=')[1];
                setDefaultEndTime(defaultEndTimeCookieValue);
            }

            setIsLoading(false);
        }

        load();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleDefaultStartTimeChange = (time) => {
        setDefaultStartTime(time);
        document.cookie = `defaultStartTime=${time}`;
    }

    const handleDefaultEndTimeChange = (time) => {
        setDefaultEndTime(time);
        document.cookie = `defaultEndTime=${time}`;
    }

    const handleSave = async () => {

        if (isSaving) {
            return;
        }

        if (!organization.name ||
            !organization.organizationNumber ||
            !organization.address ||
            !organization.zipCode ||
            !organization.city ||
            !organization.phone ||
            !organization.email) {
            showToast(t('generic.missingFieldsTitle'), t('generic.missingFieldsDescription'), 'error');
            return;
        }

        setIsSaving(true);

        try {
            const token = await getAccessTokenSilently();
            const result = await updateOrganization(token, organization);

            if (result && !result.error) {
                setOrganization(result);
                showToast(t('settingsPage.organizationUpdatedTitle'), t('settingsPage.organizationUpdatedDescription'), 'success');
            } else {
                console.error(result);
                showToast(t('generic.errorTitle'), t('generic.errorDescription'), 'error');
            }

        } catch (error) {
            console.error(error);
            showToast(t('generic.errorTitle'), t('generic.errorDescription'), 'error');
        }

        setIsSaving(false);
    }

    if (isLoading) {
        return (<WidgetLoadingSkeleton />);
    }

    if (!isLoading && organization) {
        return (
            <div className="flex flex-col">
                <Widget className="mb-6">
                    <h1 className="text-xl font-medium pb-1">{t('settingsPage.title')}</h1>
                    <span>{t('settingsPage.description')}</span>
                    <div className="flex md:flex-row flex-col gap-3 mt-3">
                        <div className="md:w-1/2">
                            <InputText
                                value={organization.name}
                                onChange={(value) => { setOrganization({ ...organization, name: value }) }}
                                title={t('settingsPage.name')}
                                required={true} />
                        </div>
                        <div className="md:w-1/2">
                            <InputText
                                value={organization.organizationNumber ?? ''}
                                onChange={(value) => { setOrganization({ ...organization, organizationNumber: value }) }}
                                title={t('settingsPage.organizationNumber')}
                                required={true} />
                        </div>
                    </div>
                    <div className="flex gap-3 mt-3 md:flex-row flex-col">
                        <div className="md:w-1/2">
                            <InputText
                                value={organization.address ?? ''}
                                title={t('settingsPage.address')}
                                onChange={(value) => { setOrganization({ ...organization, address: value }) }}
                                required={true} />
                        </div>
                        <div className="md:w-1/2">
                            <InputText
                                value={organization.zipCode ?? ''}
                                title={t('settingsPage.zipCode')}
                                onChange={(value) => { setOrganization({ ...organization, zipCode: value }) }}
                                required={true} />
                        </div>
                    </div>
                    <div className="flex gap-3 mt-3 md:flex-row flex-col">
                        <div className="md:w-1/2">
                            <InputText
                                value={organization.city ?? ''}
                                title={t('settingsPage.city')}
                                onChange={(value) => { setOrganization({ ...organization, city: value }) }}
                                required={true} />
                        </div>
                        <div className="md:w-1/2">
                            <InputText
                                value={organization.countryName ?? ''}
                                title={t('settingsPage.country')}
                                disabled={true} />
                        </div>
                    </div>
                    <div className="flex gap-3 mt-3 md:flex-row flex-col">
                        <div className="md:w-1/2">
                            <InputText
                                value={organization.phone ?? ''}
                                title={t('settingsPage.phoneNumber')}
                                type="tel"
                                onChange={(value) => { setOrganization({ ...organization, phone: value }) }}
                                required={true} />
                        </div>
                        <div className="md:w-1/2">
                            <InputEmail
                                value={organization.email ?? ''}
                                title={t('settingsPage.email')}
                                onChange={(value) => { setOrganization({ ...organization, email: value }) }}
                                required={true} />
                        </div>
                    </div>
                    <div className="flex gap-3 mt-3 md:flex-row flex-col">
                        <div className="md:w-1/2">
                        <div className="flex flex-col">
                                <span className="text-base font-medium pb-1">{t('settingsPage.defaultStartTime')}</span>
                                <input
                                    value={defaultStartTime ?? ''}
                                    onChange={(e) => { handleDefaultStartTimeChange(e.target.value); }}
                                    type="time"
                                    className="border-airpark-gray-300 hover:border-airpark-gray-400 border-2 h-11 rounded-xl text-airpark-green font-medium text-center focus:outline-none" />
                            </div>
                        </div>
                        <div className="md:w-1/2">
                            <div className="flex flex-col">
                                <span className="text-base font-medium pb-1">{t('settingsPage.defaultEndTime')}</span>
                                <input
                                    value={defaultEndTime ?? ''}
                                    onChange={(e) => { handleDefaultEndTimeChange(e.target.value); }}
                                    type="time"
                                    className="border-airpark-gray-300 hover:border-airpark-gray-400 border-2 h-11 rounded-xl text-airpark-green font-medium text-center focus:outline-none" />
                            </div>
                        </div>
                    </div>
                    <div className="flex gap-3 mt-3 md:flex-row flex-col">
                        <div className="md:w-1/2">
                        <div className="flex flex-col">
                                <span className="text-base font-medium pb-1">{t('settingsPage.frontDeskEmailConfirmationTextTitle')}</span>
                                <textarea 
                                    value={organization.frontDeskEmailConfirmationText ?? ''}
                                    onChange={(e) => { setOrganization({ ...organization, frontDeskEmailConfirmationText: e.target.value }); }}
                                    rows="10"
                                    cols="50"
                                    className="w-full h-11 bg-airpark-gray-300 rounded-xl p-2 pl-4 text-airpark-input-field focus:outline-none min-h-[100px]"></textarea>
                            </div>
                        </div>
                    </div>
                    <div className="flex justify-center">
                        <ButtonText className="mt-6 max-w-xs w-full" onClick={() => { handleSave(); }} isLoading={isSaving}>
                            {t('generic.save')}
                        </ButtonText>
                    </div>
                </Widget>
            </div>
        );
    }
}

export default SettingsPage;